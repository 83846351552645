import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import GWlp from "src/components/gwlp"

const GWlpData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>FabTab | Beautiful Backgrounds and the Latest Fashion News</title>
    <style type="text/css">{`
       #ctadisclosurecb2-module--ctadisclaimer {
        margin:0 auto !important;
        font-size:13px;
        width:500px;
       }

       #ctadisclosurecb2-module--ctadisclaimer p {
        font-size:12px;
        line-height:125%;
       }

    `}
    </style>
    </Helmet>
    <section>
      <GWlp data={GWlpData}></GWlp>
      </section>
    </HomepageLayout>

    
  )
  }
